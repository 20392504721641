import React from 'react'
import { injectIntl, intlShape, FormattedHTMLMessage } from 'react-intl'
import { compose } from 'recompose'
import { withPrefix } from 'gatsby'
import Recaptcha from 'react-recaptcha'

import SEO from '../components/seo'
import Map from '../components/Map'

import withPageContext from '../pageContext'
import withLayout from '../layout'

import '../styles/contact.sass'

import $ from 'jquery'

const defaultState = {
    name: '',
    nameError: false,
    email: '',
    emailError: false,
    subject: '',
    subjectError: false,
    phone: '',
    phoneError: false,
    emailText: '',
    emailTextError: false,
}

let recaptchaInstance

class ContactPage extends React.Component {
    // // const ContactPage = ({ intl }) => (
    constructor(props) {
        super(props)
    }

    state = defaultState

    handleOnChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    validate = () => {
        let isValid = true
        let errors = {
            nameError: false,
            emailError: false,
            subjectError: false,
            phoneError: false,
            emailTextError: false,
        }
        if (!this.state.name) {
            isValid = false
            errors.nameError = true
        }

        if (!this.state.email) {
            isValid = false
            errors.emailError = true
        }

        if (!this.state.phone) {
            isValid = false
            errors.phoneError = true
        }

        if (!this.state.subject) {
            isValid = false
            errors.subjectError = true
        }

        if (!this.state.emailText) {
            isValid = false
            errors.emailTextError = true
        }

        this.setState({
            ...this.state,
            ...errors,
        })

        return isValid
    }

    handleSubmit = e => {
        e.preventDefault()
        const isValid = this.validate()
        // console.log(this.state)
        if (isValid) {
            // clear form
            $.ajax({
                data: this.state,
                type: 'POST',
                url: withPrefix('/contact.php'), // use 'withPrefix' module from 'gatsby' to reference 'sendEmail.php' in 'static' folder.
                success: function (data) {
                    // console.log(data)
                    // console.info(data)
                    $('#quick-contact-success').removeClass('d-none')
                },
                error: function (xhr, status, err) {
                    // console.error(status, err.toString())
                    $('#quick-contact-error').removeClass('d-none')
                },
            })
            this.setState(defaultState)
            this.resetRecaptcha()
            $('#submit-form').prop('disabled', true)
        }
    }

    // render on captcha load
    handleCaptchaLoad(event) {
        // console.log('handleCaptchaLoad')
    }

    // load on callback verify
    verifyCallback(event) {
        // console.log('verifyCallback-->', event)
        $('#submit-form').prop('disabled', false)
    }

    // create a reset function
    resetRecaptcha = () => {
        recaptchaInstance.reset()
    }

    render() {
        const { intl } = this.props;
        return (
            <React.Fragment>
                <SEO
                    title={this.props.intl.formatMessage({
                        id: 'contact.title',
                    })}
                />
                <main id="contact-page">
                    <section
                        className="page-section"
                        style={{ paddingTop: '3rem' }}
                    >
                        <div className="container">
                            <div className="row mt-70">
                                <div className="contact-item col-md-6 border-right border-bottom xs-no-border-right">
                                    <div className="text-center">
                                        <div className="contact-icon">
                                            <i className="fas fa-map-marked-alt mb-2"></i>
                                        </div>
                                        <h4 className="contact-title">
                                            <FormattedHTMLMessage id="contact.billinginfo" />
                                        </h4>
                                        <p className="contact-text text-muted">
                                            Advateq s.r.o. <br />
                                            Nové sady 988/2, Staré Brno, 602 00
                                            Brno <br />
                                            IČ: 08229465 <br />
                                            DIČ: CZ08229465
                                        </p>
                                    </div>
                                </div>
                                <div className="contact-item col-md-6 sm-border-right border-bottom sm-no-border-right">
                                    <div className="text-center">
                                        <div className="contact-icon">
                                            <i className="fas fa-map-marked-alt mb-2"></i>
                                        </div>
                                        <h4 className="contact-title">
                                            <FormattedHTMLMessage id="contact.address" />
                                        </h4>
                                        <p className="contact-text text-muted">
                                            Vídeňská 141/117b, Dolní Heršpice, 619 00 Brno-jih
                                        </p>
                                    </div>
                                </div>
                                <div className="contact-item col-md-6 border-right xs-no-border-right xs-border-bottom">
                                    <div className="text-center">
                                        <div className="contact-icon">
                                            <i className="fas fa-phone mb-2"></i>
                                        </div>
                                        <h4 className="contact-title">
                                            <FormattedHTMLMessage id="contact.phone" />
                                        </h4>
                                        <p className="contact-text text-muted">
                                            +420 732 319 478
                                            <br />
                                            +420 728 496 246
                                        </p>
                                    </div>
                                </div>
                                <div className="contact-item col-md-6">
                                    <div className="text-center">
                                        <div className="contact-icon">
                                            <i className="fas fa-envelope mb-2"></i>
                                        </div>
                                        <h4 className="contact-title">
                                            <FormattedHTMLMessage id="contact.email" />
                                        </h4>
                                        <p className="contact-text text-muted">
                                            info@advateq.cz
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section
                        id="quick-contact"
                        className="page-section bg-light-gray"
                    >
                        <div className="container">
                            <div className="row justify-content-center section-header mb-5">
                                <div className="text-center">
                                    <h1>
                                        <FormattedHTMLMessage id="contact.quickcontant" />
                                    </h1>
                                    <hr className="divider light my-4" />
                                    <p>
                                        <FormattedHTMLMessage id="contact.quickcontant.text" />
                                    </p>
                                </div>
                            </div>
                            <form
                                id="quick-contact-form"
                                className="js-validate"
                                action=""
                                method="post"
                                onSubmit={this.handleSubmit}
                            >
                                <div className="row">
                                    <div className="col-sm-6 mb-6">
                                        <div className="js-form-message">
                                            <label className="form-label">
                                                <FormattedHTMLMessage id="contact.quickcontant.name" />
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <div className="advateq-focus-state">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="name"
                                                    placeholder={intl.formatMessage({ id: 'contact.quickcontant.name.placeholder' })}
                                                    required=""
                                                    value={this.state.name}
                                                    onChange={
                                                        this.handleOnChange
                                                    }
                                                />
                                            </div>
                                            <div
                                                id="name-error"
                                                className="invalid-feedback"
                                                style={{
                                                    display: this.state
                                                        .nameError
                                                        ? 'block'
                                                        : '',
                                                }}
                                            >
                                                <FormattedHTMLMessage id="contact.quickcontant.name.valid" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 mb-6">
                                        <div className="js-form-message">
                                            <label className="form-label">
                                                <FormattedHTMLMessage id="contact.quickcontant.email" />
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <div className="advateq-focus-state">
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    name="email"
                                                    placeholder={intl.formatMessage({ id: 'contact.quickcontant.email.placeholder' })}
                                                    required=""
                                                    value={this.state.email}
                                                    onChange={
                                                        this.handleOnChange
                                                    }
                                                />
                                            </div>
                                            <div
                                                id="email-error"
                                                className="invalid-feedback"
                                                style={{
                                                    display: this.state
                                                        .emailError
                                                        ? 'block'
                                                        : '',
                                                }}
                                            >
                                                <FormattedHTMLMessage id="contact.quickcontant.email.valid" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-100"></div>

                                    <div className="col-sm-6 mb-6 mt-3">
                                        <div className="js-form-message">
                                            <label className="form-label">
                                                <FormattedHTMLMessage id="contact.quickcontant.subject" />
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <div className="advateq-focus-state">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="subject"
                                                    placeholder={intl.formatMessage({ id: 'contact.quickcontant.subject.placeholder' })}
                                                    aria-label={intl.formatMessage({ id: 'contact.quickcontant.subject.placeholder' })}
                                                    required=""
                                                    value={this.state.subject}
                                                    onChange={
                                                        this.handleOnChange
                                                    }
                                                />
                                            </div>
                                            <div
                                                id="subject-error"
                                                className="invalid-feedback"
                                                style={{
                                                    display: this.state
                                                        .subjectError
                                                        ? 'block'
                                                        : '',
                                                }}
                                            >
                                                <FormattedHTMLMessage id="contact.quickcontant.subject.valid" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 mb-6 mt-3">
                                        <div className="js-form-message">
                                            <label className="form-label">
                                                <FormattedHTMLMessage id="contact.quickcontant.phone" />
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <div className="advateq-focus-state">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    name="phone"
                                                    placeholder={intl.formatMessage({ id: 'contact.quickcontant.phone.placeholder' })}
                                                    aria-label={intl.formatMessage({ id: 'contact.quickcontant.phone.placeholder' })}
                                                    required=""
                                                    value={this.state.phone}
                                                    onChange={
                                                        this.handleOnChange
                                                    }
                                                />
                                            </div>
                                            <div
                                                id="phone-error"
                                                className="invalid-feedback"
                                                style={{
                                                    display: this.state
                                                        .phoneError
                                                        ? 'block'
                                                        : '',
                                                }}
                                            >
                                                <FormattedHTMLMessage id="contact.quickcontant.phone.valid" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="js-form-message mb-6 mt-3">
                                    <label className="form-label">
                                        <FormattedHTMLMessage id="contact.quickcontant.helpText" />
                                        <span className="text-danger">*</span>
                                    </label>

                                    <div className="input-group advateq-focus-state">
                                        <textarea
                                            className="form-control"
                                            rows="4"
                                            name="emailText"
                                            placeholder={intl.formatMessage({ id: 'contact.quickcontant.helpText.placeholder' })}
                                            required=""
                                            value={this.state.emailText}
                                            onChange={this.handleOnChange}
                                        ></textarea>
                                    </div>
                                    <div
                                        id="text-error"
                                        className="invalid-feedback"
                                        style={{
                                            display: this.state.emailTextError
                                                ? 'block'
                                                : '',
                                        }}
                                    >
                                        <FormattedHTMLMessage id="contact.quickcontant.helpText.valid" />
                                    </div>
                                </div>

                                <div className="mt-4 d-flex justify-content-center">
                                    <Recaptcha
                                        ref={e => (recaptchaInstance = e)}
                                        sitekey="6LddM30UAAAAAPXPJ2Q_hlhUnfpY-u-QsY8OefGc"
                                        render="explicit"
                                        verifyCallback={this.verifyCallback}
                                        onloadCallback={this.handleCaptchaLoad}
                                    />
                                </div>

                                <div className="mt-4 text-center">
                                    <button
                                        id="submit-form"
                                        type="submit"
                                        className="btn btn-custom btn-custom-red transition-3d-hover mb-4"
                                        disabled
                                    >
                                        <FormattedHTMLMessage id="contact.quickcontant.send" />
                                    </button>
                                </div>
                            </form>
                            <div
                                id="quick-contact-success"
                                className="alert alert-success d-none"
                                role="alert"
                            >
                                <FormattedHTMLMessage id="contact.quickcontant.sentOK" />
                            </div>
                            <div
                                id="quick-contact-error"
                                className="alert alert-danger d-none"
                                role="alert"
                            >
                                <FormattedHTMLMessage id="contact.quickcontant.sentErr" />
                            </div>
                        </div>
                    </section>

                    <section className="container mt-5 mb-5">
                        <div id="map">
                            <Map />
                        </div>
                    </section>
                </main>
            </React.Fragment>
            // )
        )
    }

    // // Prepare state for storing form data
    // state = {
    //     email: '',
    //     message: '',
    //     name: '',
    // }

    // // Method to handle inputs
    // handleInputChange = event => {
    //     // Check if name input contains text.
    //     // Don't test email, yet.
    //     console.log('change')
    //     if (event.target.value.length > 0 && event.target.name !== 'email') {
    //         this.setState({
    //             [event.target.name]: event.target.value,
    //         })
    //     }

    //     // Run a simple test to validate email address
    //     if (event.target.name === 'email') {
    //         var re = /^(([^<>()[]\\.,;:s@"]+(.[^<>()[]\\.,;:s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/

    //         if (re.test(String(event.target.value).toLowerCase())) {
    //             this.setState({
    //                 [event.target.name]: event.target.value,
    //             })
    //         }
    //     }
    // }

    // // Method to handle form submission
    // handleFormSubmit = event => {
    //     console.log('submit')
    //     event.preventDefault()

    //     // Test required fields - email and name
    //     if (this.state.email.length > 0 && this.state.name.length > 0) {
    //         // Send the data with Ajax and jQuery
    //         $.ajax({
    //             data: this.state,
    //             type: 'POST',
    //             url: withPrefix('/sendEmail.php'), // use 'withPrefix' module from 'gatsby' to reference 'sendEmail.php' in 'static' folder.
    //             success: function(data) {
    //                 console.info(data)
    //             },
    //             error: function(xhr, status, err) {
    //                 console.error(status, err.toString())
    //             },
    //         })
    //     }
    // }

    // render() {
    //     return (
    //         <section>
    //             <h1>Let's get in touch</h1>

    //             <form action="">
    //                 <fieldset>
    //                     <label htmlFor="name">Full name</label>

    //                     <input
    //                         onChange={this.handleInputChange}
    //                         type="text"
    //                         name="name"
    //                         id="name"
    //                         required={true}
    //                     />
    //                 </fieldset>

    //                 <fieldset>
    //                     <label htmlFor="email">Email address</label>

    //                     <input
    //                         onChange={this.handleInputChange}
    //                         type="email"
    //                         name="email"
    //                         id="email"
    //                         required={true}
    //                     />
    //                 </fieldset>

    //                 <h2>Your message</h2>

    //                 <textarea
    //                     onChange={this.handleInputChange}
    //                     name="message"
    //                     id="message"
    //                 />

    //                 <fieldset>
    //                     <button onClick={this.handleFormSubmit}>Send</button>
    //                 </fieldset>
    //             </form>
    //         </section>
    //     )
    // }
}

ContactPage.propTypes = {
    intl: intlShape.isRequired,
}

export default compose(withPageContext, withLayout, injectIntl)(ContactPage)
