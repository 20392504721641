import React from 'react'
import { compose, withProps } from 'recompose'
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
} from 'react-google-maps'
import { MarkerWithLabel } from 'react-google-maps/lib/components/addons/MarkerWithLabel'

const labelSize = { width: 220 }
const labelPadding = 8

const styles = [
    {
        elementType: 'geometry',
        stylers: [
            {
                color: '#f5f5f5',
            },
        ],
    },
    {
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#616161',
            },
        ],
    },
    {
        elementType: 'labels.text.stroke',
        stylers: [
            {
                color: '#f5f5f5',
            },
        ],
    },
    {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#bdbdbd',
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
            {
                color: '#eeeeee',
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#757575',
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
            {
                color: '#e5e5e5',
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#9e9e9e',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
            {
                color: '#ffffff',
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#757575',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
            {
                color: '#dadada',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#616161',
            },
        ],
    },
    {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#9e9e9e',
            },
        ],
    },
    {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [
            {
                color: '#e5e5e5',
            },
        ],
    },
    {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [
            {
                color: '#eeeeee',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
            {
                color: '#c9c9c9',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#9e9e9e',
            },
        ],
    },
]

const MyMapComponent = compose(
    withProps({
        googleMapURL:
            'https://maps.googleapis.com/maps/api/js?key=AIzaSyBdE7hJARzeMvmD_yitrYKTKwG35KIZQ2Q&v=3.exp&libraries=geometry,drawing,markers',
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `600px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
        markers: [
            {
                lat: 49.188926,
                lng: 16.606963,
                id: 1,
                title: 'Sídlo',
                label: 'Sídlo',
                sub: "Nové sady 988/2, Staré Brno, 602 00 Brno"
            },
            {
                lat: 49.153597,
                lng: 16.604767,
                id: 2,
                title: 'Provozovna',
                label: 'Provozovna',
                sub: "Vídeňská 141/117b, Dolní Heršpice, 619 00 Brno-jih"
            },
        ],
    }),
    withScriptjs,
    withGoogleMap
)(props => (
    <GoogleMap
        defaultZoom={12}
        defaultCenter={{ lat: 49.188926, lng: 16.606963 }}
        defaultOptions={{ styles: styles }}
    >
        {props.isMarkerShown &&
            props.markers.map(marker => (
                // <Marker
                //     position={{ lat: marker.lat, lng: marker.lng }}
                //     key={marker.id}
                //     title={marker.title}
                //     label={marker.label}
                // />

                <MarkerWithLabel
                    labelStyle={{
                        textAlign: 'center',
                        width: labelSize.width + 'px',
                        backgroundColor: '#fff',
                        border: '1px solid #000',
                        fontSize: '14px',
                        padding: labelPadding + 'px',
                    }}
                    labelAnchor={{
                        x: labelSize.width / 2 + labelPadding,
                        y: 110,
                    }}
                    key={marker.id}
                    position={{ lat: marker.lat, lng: marker.lng }}
                >
                    <span><b>{marker.label}</b><br/>{marker.sub}</span>
                </MarkerWithLabel>
            ))}
    </GoogleMap>
))

class MyFancyComponent extends React.PureComponent {
    state = {
        isMarkerShown: false,
    }

    componentDidMount() {
        this.delayedShowMarker()
    }

    delayedShowMarker = () => {
        setTimeout(() => {
            this.setState({ isMarkerShown: true })
        }, 3000)
    }

    handleMarkerClick = () => {
        this.setState({ isMarkerShown: false })
        this.delayedShowMarker()
    }

    render() {
        return (
            <MyMapComponent
                isMarkerShown={this.state.isMarkerShown}
                onMarkerClick={this.handleMarkerClick}
            />
        )
    }
}

export default MyFancyComponent
